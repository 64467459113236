import * as React from "react";
import { api } from "lib";
import { StatusCodes } from "http-status-codes";

interface State {
  status: string;
  error: string;
  cash_total: number;
  cash_count: number;
  cash_retail: number;
  cash_bulk: number;
  credit_total: number;
  credit_count: number;
  credit_retail: number;
  credit_bulk: number;
  total_nib_qty: number;
  total_used_qty: number;
  total_minifig_qty: number;
  total_animal_qty: number;
  total_cmf_qty: number;
  total_bulk_qty: number;
  total_misc_qty: number;
}

interface Props {
  monthlySummary: State,
  getMonthlySummary: () => void,
}

const defaultTotals = {
  cash_total: 0,
  cash_count: 0,
  cash_retail: 0,
  cash_bulk: 0,
  credit_total: 0,
  credit_count: 0,
  credit_retail: 0,
  credit_bulk: 0,
  total_nib_qty: 0,
  total_used_qty: 0,
  total_minifig_qty: 0,
  total_animal_qty: 0,
  total_cmf_qty: 0,
  total_bulk_qty: 0,
  total_misc_qty: 0,
};

export const useBuysMonthlySummary = (): Props => {
  const [state, setState] = React.useState<State>({ ...defaultTotals, status: api.idle, error: '' });

  const getMonthlySummary = React.useCallback(async () => {
    setState((prevState) => ({ ...prevState, status: api.loading, error: '' }));
    const d = new Date();
    const options = {
      month: `${d.getMonth() + 1}`,
      year: `${d.getFullYear()}`,
      offset: `${new Date().getTimezoneOffset()}`
    };
    const resp = await api.get('/api/buys/monthly_summary', options);
    if (resp.status === StatusCodes.OK) {
      setState({
        status: api.success,
        error: '',
        cash_total: resp.body.cash_total,
        cash_count: resp.body.cash_count,
        cash_retail: resp.body.cash_retail,
        cash_bulk: resp.body.cash_bulk,
        credit_total: resp.body.credit_total,
        credit_count: resp.body.credit_count,
        credit_retail: resp.body.credit_retail,
        credit_bulk: resp.body.credit_bulk,
        total_nib_qty: resp.body.total_nib_qty,
        total_used_qty: resp.body.total_used_qty,
        total_minifig_qty: resp.body.total_minifig_qty,
        total_animal_qty: resp.body.total_animal_qty,
        total_cmf_qty: resp.body.total_cmf_qty,
        total_bulk_qty: resp.body.total_bulk_qty,
        total_misc_qty: resp.body.total_misc_qty,
      });
    } else {
      setState({ ...defaultTotals, status: api.error, error: api.error });
    }
  }, [setState]);

  return {
    monthlySummary: {
      ...state,
    },
    getMonthlySummary,
  };
};
