import * as React from "react";
import { api } from "lib";
import { CustomerModel } from "model";
import { StatusCodes } from "http-status-codes";

interface State {
  status: string;
  error: string;
  customer: CustomerModel | null;
}

interface Props {
  syncCustomer: State,
  doSyncCustomer: (id: number) => void;
  resetSyncCustomer: () => void;
}

const initialState = {
  status: api.idle,
  error: "",
  customer: null,
};

export const useSyncCustomer = (): Props => {
  const [state, setState] = React.useState<State>(initialState);

  const sync = React.useCallback(async (customer_id: number) => {
    setState({ status: api.loading, customer: null, error: "" });
    const resp = await api.get(`/api/customers/${customer_id}/sync`);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, customer: resp.body, error: "" });
    } else if (resp.status === StatusCodes.NOT_FOUND) {
      setState({ status: api.error, customer: null, error: "Customer not found" });
    } else {
      setState({ status: api.error, customer: null, error: resp.body.error });
    }
  }, []);

  const reset = () => {
    setState(initialState);
  };

  return {
    syncCustomer: {
      ...state
    },
    doSyncCustomer: sync,
    resetSyncCustomer: reset,
  };
};
