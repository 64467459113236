import * as User from './user';

export * from './address';
export * from './animal';
export * from './animal_buy_line';
export * from './animal_price_band';
export * from './bricklink_price';
export * from './bulk_buy_line';
export * from './bulk_price_band';
export * from './buy';
export * from './buy_setting';
export * from './buy_settings';
export * from './buy_store_credit_log';
export * from './customer';
export * from './customer_import';
export * from './daily_buy';
export * from './heartland';
export * from './heartland_custom_field';
export * from './infobar';
export * from './inventory_setting';
export * from './lego_set';
export * from './lego_set_image_model';
export * from './minifig';
export * from './minifig_buy_line';
export * from './minifig_price_band';
export * from './misc_buy_line';
export * from './misc_price_band';
export * from './monthly_buy';
export * from './new_set_buy_line';
export * from './rewards_signup_form';
export * from './series_minifig';
export * from './series_minifig_buy_line';
export * from './session';
export * from './unit';
export * from './used_set_buy_line';
export * from './user';

export const models = {
  user: {
    SUPER_ADMIN: User.SUPER_ADMIN,
    ADMIN: User.ADMIN,
    OWNER: User.OWNER,
    USER: User.USER,
    isAdmin: User.isAdmin,
    isOwner: User.isOwner,
    isSuperAdmin: User.isSuperAdmin,
    getUserType: User.getUserType,
  },
};
