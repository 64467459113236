import { HeartlandModel } from './heartland';
import { UnitModel } from './unit';
import { UserSetting } from './user_setting';

export const SUPER_ADMIN: string = 'super_admin';
export const ADMIN: string = 'admin';
export const OWNER: string = 'owner';
export const USER: string = 'user';

export interface UserModel {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  display_name: string;
  email: string;
  user_type: string;
  status?: string;
  heartlands?: HeartlandModel[];
  units?: UnitModel[];
  settings: UserSetting[];
  primary_heartland_id?: number;
  hide_as_employee?: boolean;
}

export const isAdmin = (user: UserModel): boolean => user.user_type === ADMIN || user.user_type === SUPER_ADMIN;
export const isOwner = (user: UserModel): boolean => user.user_type === OWNER;
export const isSuperAdmin = (user: UserModel): boolean => user.user_type === SUPER_ADMIN;

export const getUserType = (user: UserModel) => {
  switch (user.user_type) {
    case SUPER_ADMIN:
      return "Super Admin";
    case ADMIN:
      return "Admin";
    case OWNER:
      return "Admin";
    case USER:
      return "User";
    default:
      return "Unknown";
  }
};

