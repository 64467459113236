import * as React from "react";
import { api } from "lib";
import { AnimalModel, LegoSetModel, MinifigModel, SeriesMinifigModel } from "model";
import { StatusCodes } from "http-status-codes";

interface Props {
  status: string;
  error: string;
  items: any[];
  total: number;
  getItem: (heartland_id: number, item: LegoSetModel | AnimalModel | MinifigModel | SeriesMinifigModel) => void;
}

export const useHeartlandItems = (): Props => {
  const [status, setStatus] = React.useState(api.idle);
  const [error, setError] = React.useState<string>('');
  const [total, setTotal] = React.useState<number>(0);
  const [items, setItems] = React.useState<any[]>([]);

  const getItem = React.useCallback(async (heartland_id: number, item: AnimalModel | LegoSetModel | MinifigModel | SeriesMinifigModel) => {
    setStatus(api.loading);
    const resp = await api.get('/api/lego_sets/heartland_items', { heartland_id: heartland_id.toString(), query: item.bricklink_id });
    if (resp.status === StatusCodes.OK) {
      setStatus(api.success);
      setItems(resp.body.results);
      setTotal(resp.body.total);
    } else {
      setStatus(api.error);
      setError(resp.body);
    }
  }, []);

  return {
    status,
    error,
    items,
    total,
    getItem,
  };
};

