import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Grid, LinearProgress, Link, TextField } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { SessionContext, TSessionContext, api } from 'lib';
import useStyles from './styles';
import { CustomerModel, InfoBarModel } from 'model';
import { InfoBar, NotFound } from 'components';
import { DataGrid } from '@mui/x-data-grid';
import { BuyColumns } from 'components/tables';
import { useBuysList, useGetCustomer, useSyncCustomer } from 'api';

interface FormProps {
  customer: CustomerModel;
}

export const CustomerForm: React.FC<FormProps> = (props: FormProps) => {
  const [customer, setCustomer] = React.useState<CustomerModel>(props.customer);
  const { isAdmin, currentHeartland } = React.useContext(SessionContext) as TSessionContext;
  const { buysList, listBuysByCustomer } = useBuysList();
  const { doSyncCustomer, resetSyncCustomer, syncCustomer } = useSyncCustomer();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  React.useEffect(() => {
    if (customer.id) {
      listBuysByCustomer(customer.id, paginationModel.page, paginationModel.pageSize);
    }
  }, [customer, paginationModel, listBuysByCustomer]);

  React.useEffect(() => {
    if (syncCustomer.status === api.success && syncCustomer.customer) {
      setCustomer(syncCustomer.customer);
      setInfoBar({ status: "success", message: "Customer Synced" });
    } else if (syncCustomer.status === api.error) {
      setInfoBar({ status: "error", message: syncCustomer.error });
    } else {
      setInfoBar(null);
    }
  }, [syncCustomer]);


  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);

  const clearInfoBar = () => {
    setInfoBar(null);
    resetSyncCustomer();
  };

  const handleSync = () => {
    if (customer.id) {
      doSyncCustomer(customer.id);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <h2> <Link href="/customers" className="nav">Customers</Link> :: {customer.first_name} {customer.last_name}</h2>
      </Grid>
      <Grid item xs={12} md={12}>
        {infoBar &&
          <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
        }
      </Grid>
      <Grid item xs={12} md={9}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              id="id"
              label="Patron ID"
              variant="outlined"
              fullWidth
              value={customer.id}
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="external_id"
              label="Heartland ID"
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              value={customer.external_id}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="first_name"
              label="First Name"
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              value={customer.first_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="last_name"
              label="Last Name"
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              value={customer.last_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              value={customer.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="phone"
              label="Phone Number"
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              value={customer.phone}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="store_credit_balance"
              label="Store Credit Balance"
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              value={customer.store_credit_balance}
            />
          </Grid>
          {isAdmin() &&
            <Grid item xs={12} md={12}>
              <h3>Associated Units</h3>
              <Grid container spacing={2}>
                {customer.units && customer.units.map((u) =>
                  <Grid item key={u.id} xs={6}><Link href={`/units/${u.id}`}>{u.name}</Link></Grid>
                )}
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
      <Grid item xs={12} md={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              aria-label="Sync with Heartland"
              startIcon={<SyncIcon />}
              onClick={handleSync}
              disabled={syncCustomer.status === api.loading}
              fullWidth>
              Sync with Heartland
            </Button>
          </Grid>
          {(currentHeartland && customer.heartland_id) &&
            <>
              <div className="info">
                Heartland ID: <Link href={`https://${currentHeartland.domain}.retail.heartland.us/#customers/edit/${customer.heartland_id}`}>{customer.heartland_id}</Link>
              </div>
            </>
          }
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <h3>Buy/Trade History</h3>
        {buysList.status === api.error &&
          <InfoBar status="error" message={buysList.error} />
        }
        <Box sx={{ width: '95%', pt: 2, fontSize: '0.9rem' }}>
          <DataGrid
            rows={buysList.buys}
            columns={BuyColumns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            loading={buysList.status === api.loading}
            rowCount={buysList.total}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            disableRowSelectionOnClick
            paginationMode="server"
          />
        </Box>
      </Grid>
    </Grid >
  );
};

export const CustomerShow: React.FC = () => {
  const { currentUser } = React.useContext(SessionContext) as TSessionContext;
  const { customer_id } = useParams();
  const { customer, status, get } = useGetCustomer();
  const classes = useStyles();

  React.useEffect(() => {
    if (customer_id) {
      get(parseInt(customer_id));
    }
  }, [customer_id, get]);

  if (status === api.loading) {
    return (<div style={{ marginTop: 32, width: 800 }}><LinearProgress /></div>);
  } else if (status === api.error || !currentUser) {
    return (
      <div style={{ width: 800, margin: '0 auto', textAlign: 'center' }}>
        <NotFound />
        <h4><Link href="/customers">Return to Customers List</Link></h4>
      </div >);
  } else if (status === api.success && customer) {
    return (
      <div className={classes.show}>
        <CustomerForm customer={customer} />
      </div>
    );
  }
  return (<></>);
};