import * as React from 'react';
import { debounce } from 'lodash';
import { LinearProgress, Grid, Button } from '@mui/material';

import { useAnimalsSearch } from 'api';
import { AnimalThumbnail, AnimalCard, CardModal } from 'components';
import { api } from 'lib';
import { AnimalModel } from 'model';

interface Props {
  query: string;
  onClick?: (minifig: AnimalModel) => void;
  maxResults?: number
}

export const AnimalSearch: React.FC<Props> = (props: Props) => {
  const { animalSearch, doAnimalSearch } = useAnimalsSearch();
  const [page, setPage] = React.useState({ index: 0, total: 0 });
  const [selected, setSelected] = React.useState<AnimalModel | null>(null);
  const handleOpen = (minifig: AnimalModel) => setSelected(minifig);
  const handleClose = () => setSelected(null);
  const maxResults = React.useMemo(() => props.maxResults ? props.maxResults : 12, [props.maxResults]);

  const doSearch = React.useMemo(() => debounce(doAnimalSearch, 500), [doAnimalSearch]);

  React.useEffect(() => {
    if (props.query.length > 2 && props.query.indexOf(',') < 0) {
      doSearch(props.query, page.index, maxResults);
    }
  }, [props.query, page.index, doSearch, maxResults]);

  React.useEffect(() => {
    setPage({ ...page, total: Math.ceil(animalSearch.total / maxResults) })
  }, [animalSearch.total, maxResults]);

  const nextPage = () => {
    const newIndex = page.index + 1;
    setPage({ index: newIndex > page.total - 1 ? page.total - 1 : newIndex, total: page.total });
  };
  const prevPage = () => {
    const newIndex = page.index - 1;
    setPage({ index: newIndex < 0 ? 0 : newIndex, total: page.total });
  };

  if (animalSearch.status === api.loading) {
    return (<div style={{ margin: '32px 0', width: 800 }}><LinearProgress /></div>);
  } else if (animalSearch.status === api.success) {
    return (
      <>
        <Grid container alignItems="center" spacing={1}>
          {animalSearch.animals && animalSearch.animals.map((animal =>
            <Grid item xs={12} md={2} key={animal.id}>
              <div onClick={() => handleOpen(animal)}>
                <AnimalThumbnail animal={animal} onClick={props.onClick} />
              </div>
            </Grid>
          ))}
        </Grid>
        <Grid container alignItems="center" spacing={1} sx={{ marginTop: 1 }}>
          <Grid item xs={12} md={12} sx={{ textAlign: 'right', fontSize: '0.9em', marginBottom: 20 }}>
            <Button onClick={prevPage}><strong>&lt;</strong></Button>
            <Button onClick={nextPage}><strong>&gt;</strong></Button>
            page {page.index + 1} of {page.total} (Total {animalSearch.total})
          </Grid>
        </Grid >
        <CardModal
          open={selected !== null}
          onClose={handleClose}
        >
          <AnimalCard animal={selected} />
        </CardModal>
      </>
    );
  } else if (animalSearch.status === api.error) {
    return (<>{animalSearch.error}</>);
  } else {
    return (<></>);
  }
};