import * as React from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import { SeriesMinifigModel } from 'model';
import { SessionContext, TSessionContext, formatCurrency } from 'lib';
import moment from 'moment';
import { SeriesMinifigMyStoreInfo } from './my_store_info';

interface Props {
  minifig: SeriesMinifigModel | null;
}

export const CMFCard: React.FC<Props> = ({ minifig }: Props) => {
  const formatPrice = (val: any): string => val ? '$' + Number(val).toFixed(2) : 'N/A';
  const { isHeartlandLive } = React.useContext(SessionContext) as TSessionContext;

  if (!minifig) {
    return (<></>);
  }
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6">{minifig.name} / {minifig.series}  / {minifig.release_year} </Typography>
        </Grid>
        <Grid item xs={4}>
          <img src={minifig.rebrickable_image_url} alt={minifig.name} style={{ display: 'block', maxWidth: 370, maxHeight: 370 }} />
          <Grid container spacing={0.25}>
            <Grid item xs={4}>
              <Typography variant="body2">Bricklink:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                <Link href={`https://www.bricklink.com/v2/catalog/catalogitem.page?M=${minifig.bricklink_id}`}>{minifig.bricklink_id}</Link>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">Rebrickable:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                <Link href={`https://rebrickable.com/minifigs/${minifig.rebrickable_id}`}>{minifig.rebrickable_id}</Link>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">BrickOwl:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                <Link href={`https://brickowl.com/catalog/${minifig.brickowl_id}`}>{minifig.brickowl_id}</Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          {isHeartlandLive() && <SeriesMinifigMyStoreInfo minifig={minifig} />}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}><Typography variant="body1" sx={{ fontWeight: 700 }}>Bricklink</Typography></Grid>
                <Grid item xs={1}>&nbsp;</Grid>
                <Grid item xs={3}><strong>Used</strong></Grid>
                <Grid item xs={3}><strong> New</strong></Grid>
                <Box style={{ width: '100%' }}></Box>
                <Grid item xs={1}>avg:</Grid>
                <Grid item xs={3}>{formatPrice(minifig.used_bricklink_prices.avg)}</Grid>
                <Grid item xs={3}>{formatPrice(minifig.new_bricklink_prices.avg)}</Grid>
                <Box style={{ width: '100%' }}></Box>
                <Grid item xs={1}>min:</Grid>
                <Grid item xs={3}>{formatPrice(minifig.used_bricklink_prices.min)}</Grid>
                <Grid item xs={3}>{formatPrice(minifig.new_bricklink_prices.min)}</Grid>
                <Box style={{ width: '100%' }}></Box>
                <Grid item xs={1}>max:</Grid>
                <Grid item xs={3}>{formatPrice(minifig.used_bricklink_prices.max)}</Grid>
                <Grid item xs={3}>{formatPrice(minifig.new_bricklink_prices.max)}</Grid>
                <Box style={{ width: '100%' }}></Box>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}><Typography varian-="body1" sx={{ fontWeight: 700 }}>Recent Buys</Typography></Grid>
                <Grid item xs={3}>Date</Grid>
                <Grid item xs={2}>Type</Grid>
                <Grid item xs={3}>Condition</Grid>
                <Grid item xs={2}>Value</Grid>
                <Grid item xs={2}>Cost</Grid>
                {minifig.recent_buys.map((line) => (
                  <>
                    <Grid item xs={3}>{moment(line.created_at).format("L")}</Grid>
                    <Grid item xs={2}>{line.buy_type}</Grid>
                    <Grid item xs={3}>{line.condition}</Grid>
                    <Grid item xs={2}>{formatCurrency(line.value)}</Grid>
                    <Grid item xs={2}>{formatCurrency(line.cost)}</Grid>
                  </>
                ))}
              </Grid>
            </Grid>
          </Grid >
        </Grid>
      </Grid>
    </Box >
  );
};
