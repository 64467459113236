import * as React from "react";
import { Grid, Typography, CircularProgress } from "@mui/material";
import { api, formatCurrency, SessionContext, TSessionContext } from "lib";
import { useHeartlandItems } from "api";
import { SeriesMinifigModel } from "model";

interface Props {
  minifig: SeriesMinifigModel;
}

export const SeriesMinifigMyStoreInfo: React.FC<Props> = ({ minifig }: Props) => {
  const { currentHeartland } = React.useContext(SessionContext) as TSessionContext;
  const { getItem, items, status } = useHeartlandItems();

  React.useEffect(() => {
    if (minifig && currentHeartland) {
      getItem(currentHeartland.id, minifig);
    }
  }, [minifig, currentHeartland, getItem]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}><Typography variant="body1" sx={{ fontWeight: 'bold' }}>My Store</Typography></Grid>
          {status === api.loading &&
            <Grid item xs={12}><CircularProgress /> Loading Data</Grid>
          }
          {status === api.success && items.length > 0 &&
            <>
              <Grid item xs={2}>Item #</Grid>
              <Grid item xs={2}>Department</Grid>
              <Grid item xs={2}>Category</Grid>
              <Grid item xs={4}>Description</Grid>
              <Grid item xs={2}>Current</Grid>
              {items.map((item) => (<>
                <Grid item xs={2}>{item.public_id}</Grid>
                <Grid item xs={2}>{item.custom.department}</Grid>
                <Grid item xs={2}>{item.custom.category}</Grid>
                <Grid item xs={4}> {item.description}</Grid>
                <Grid item xs={2}>{formatCurrency(item.price)}</Grid>
              </>))}
            </>
          }
          {status === api.success && items.length === 0 &&
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ p: 2 }}>
                Integrate store pricing by adding bricklink_id if you have this minifig
                itemized in your Heartland Inventory
              </Typography>
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  );
};