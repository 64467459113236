import * as React from 'react';
import { Box, LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useLoadBuySettings } from 'api';
import { ErrorBar, InfoBar } from 'components';
import { SessionContext, TSessionContext, api } from 'lib';
import { BuyModel, InfoBarModel } from 'model';

import { BuyProvider } from './buy_context';
import { NewSetSection } from './components/input/new_set_section';
import { MinifigSection } from './components/input/minifig_section';
import { CMFSection } from './components/input/cmf_section';
import { UsedSetSection } from './components/input/used_set_section';
import { TopBar } from './components/input/top_bar';
import { BulkSection } from './components/input/bulk_section';
import { MiscSection } from './components/input/misc_section';
import { AnimalSection } from './components/input/animal_section';

const sectionStyle = { width: "100%", mb: 4 };

interface Props {
  buy?: BuyModel;
}

export const BuyInput: React.FC<Props> = ({ buy }: Props) => {
  const { currentUnit, currentUser } = React.useContext(SessionContext) as TSessionContext;
  const navigate = useNavigate();
  const { load, settings, status, error } = useLoadBuySettings();
  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);
  const flags = useFlags();

  React.useEffect(() => {
    if (currentUnit && currentUser) {
      load(currentUnit.id);
    } else {
      navigate('/login');
    }
  }, [currentUnit, currentUser, load, navigate]);

  const onSave = (status: boolean, message: string) => {
    if (status) {
      setInfoBar({ status: "success", message: "Saved" });
    } else {
      setInfoBar({ status: "error", message: message });
    }
  };

  const clearInfoBar = () => setInfoBar(null);

  if (status === api.success && currentUser && currentUnit) {
    return (
      <BuyProvider creator={currentUser} unit={currentUnit} buy={buy} onSave={onSave}>
        {infoBar &&
          <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
        }
        <TopBar settings={settings} />
        <Box sx={{ width: "100%", mb: 36 }}>
          <Box sx={sectionStyle}> <MinifigSection /> </Box>
          <Box sx={sectionStyle}> <CMFSection /> </Box>
          <Box sx={sectionStyle}> <NewSetSection /> </Box>
          <Box sx={sectionStyle}> <UsedSetSection /> </Box>
          {flags.animalCatalog &&
            <Box sx={sectionStyle}> <AnimalSection /> </Box>
          }
          <Box sx={sectionStyle}> <BulkSection /> </Box>
          <Box sx={sectionStyle}> <MiscSection /> </Box>
        </Box>
      </BuyProvider>
    );
  } else if (status === api.loading || status === api.idle) {
    return (<LinearProgress />);
  } else if (status === api.error) {
    return (<ErrorBar error={error} />);
  } else {
    if (buy) {
      return (<>Sorry this buy is currently unavailable to view</>);
    }
    return (<>Sorry you do not have access to create a new buy</>);
  }
}; 