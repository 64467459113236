import * as React from 'react';
import { TableRow, TableCell, Box, IconButton, Checkbox as MUICheckbox, Link } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { AnimalBuyLineModel } from "model";
import { CardModal, ConditionSelector, CurrencyInput, AnimalCard, NotesInput, QuantityInput, EbayAnimalLink } from 'components';
import { formatCurrency } from 'lib';
import { BuyContext, TBuyContext } from '../../buy_context';

interface Props {
  row: AnimalBuyLineModel;
}

export const AnimalBuyEntryRow: React.FC<Props> = ({ row }: Props) => {
  const { deleteAnimal, updateAnimal } = React.useContext(BuyContext) as TBuyContext;
  const [showCard, setShowCard] = React.useState<boolean>(false);
  const handleCloseCard = () => setShowCard(false);
  const [checked, setChecked] = React.useState<boolean>(false);

  const remove = (id: number) => deleteAnimal(id);
  const update = (prop: string, value: string | number | boolean) => updateAnimal({ ...row, [prop]: value });

  return (
    <TableRow
      key={row.key}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        {row.animal &&
          <>
            {
              row.animal.image_url ?
                <img alt={row.animal.name} title={row.animal.name} src={`${row.animal.image_url}`} style={{ width: 'auto', height: 'auto', maxWidth: 80, maxHeight: 80 }} />
                : <img src="/unavailable.png" style={{ width: 'auto', height: 'auto', maxWidth: 80, maxHeight: 80 }} />
            }
          </>
        }
      </TableCell>
      <TableCell>
        {row.animal ?
          <>
            <Box sx={{ mb: 2 }}> <Link onClick={() => setShowCard(true)}>{row.description}</Link></Box>
            <CardModal open={showCard} onClose={handleCloseCard}>
              <AnimalCard animal={row.animal} />
            </CardModal>
          </> :
          <Box sx={{ mb: 2 }}>{row.description}</Box>
        }
        <NotesInput
          value={row.notes}
          onChange={(value: string) => update("notes", value)}
        />
      </TableCell>
      <TableCell>
        {row.animal ?
          <>
            <EbayAnimalLink item={row.animal} /><br />
            <Link target="_bl" href={`https://www.bricklink.com/v2/catalog/catalogitem.page?P=${row.animal.bricklink_id}#T=P`}>Bricklink</Link>
          </> :
          <>N/A</>
        }
      </TableCell>
      <TableCell>
        <ConditionSelector value={row.condition} onSelect={(value) => update("condition", value)} />
      </TableCell>
      <TableCell align="center"><MUICheckbox checked={row.overstock} onChange={(e) => update("overstock", e.target.checked)} /></TableCell>
      <TableCell align="center">
        <QuantityInput quantity={row.quantity} onChange={(value) => update("quantity", value)} />
      </TableCell>
      <TableCell><CurrencyInput value={row.value} label={''} onChange={(e) => update("value", +e.target.value)} /></TableCell>
      <TableCell align="right">{formatCurrency(row.value - row.value_discount)}</TableCell>
      <TableCell align="right">{formatCurrency(row.quantity * (row.value - row.value_discount))}</TableCell>
      <TableCell>
        <IconButton aria-label="delete" onClick={() => remove(row.key)}> <DeleteIcon /></IconButton>
        <MUICheckbox onChange={(e) => setChecked(e.target.checked)} />
      </TableCell>
    </TableRow >
  );
};