import * as React from 'react';
import { Select, MenuItem, FormControl, InputLabel, Box, Grid, TextField, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';

import { useGetHeartland, useGetInventorySettings, useHeartlandImport } from 'api';
import { CurrencyInput, InfoBar } from 'components';
import { api, formatPercentage, SessionContext, TSessionContext } from 'lib';
import { HeartlandCustomFieldModel, InfoBarModel, LegoSetModel } from 'model';

interface Props {
  legoSet: LegoSetModel;
  onClose: (reload: boolean) => void;
}

type State = Record<string, any>;

export const ImportSetDialog: React.FC<Props> = (props: Props) => {
  const { currentHeartland } = React.useContext(SessionContext) as TSessionContext;
  const { heartlandImport } = useHeartlandImport();
  const { getInventorySettings } = useGetInventorySettings();
  const { getHeartland } = useGetHeartland();
  const [info, setInfo] = React.useState<InfoBarModel | null>(null);

  const legoSetName: string = React.useMemo(() => {
    return `${props.legoSet.set_number} ${props.legoSet.name}`;
  }, [props.legoSet]);

  const [state, setState] = React.useState<State>({
    heartlandId: 0,
    itemNumberType: 'set_number',
    department: 'new_sets',
    usedSetType: 'complete',
    newSetType: 'current',
    itemNumber: props.legoSet.set_number,
    defaultCost: 0,
    description: legoSetName,
    long_description: props.legoSet.description,
    currentPrice: props.legoSet.new_bricklink_prices.avg || 0,
    custom: {
      category: props.legoSet.category,
      tax_category: 'Yes',
      bricklink_id: props.legoSet.bricklink_id,
      upc: props.legoSet.upc || "",
    }
  });

  const update = (key: string, value: string, custom: boolean = false) => {
    if (custom) {
      setState({ ...state, custom: { ...state.custom, [key]: value } });
    } else {
      setState({ ...state, [key]: value });
    }
  };

  const save = () => {
    if (currentHeartland) {
      heartlandImport.add(props.legoSet.id, currentHeartland.id, state);
    }
  };

  React.useEffect(() => {
    if (heartlandImport.status === api.success) {
      heartlandImport.reset();
      props.onClose(true);
    } else if (heartlandImport.status === api.error) {
      setInfo({ status: "error", message: heartlandImport.message });
    }
  }, [heartlandImport.status, heartlandImport.message, props.onClose]);

  React.useEffect(() => {
    if (currentHeartland) {
      getInventorySettings.get(currentHeartland.id);
      getHeartland.current();
    }
  }, []);

  const ItemCustomFields: HeartlandCustomFieldModel[] = React.useMemo(() => {
    if (getHeartland.status === api.success && getHeartland.heartland?.item_custom_fields) {
      return getHeartland.heartland.item_custom_fields.filter((field) => field.name !== 'Department');
    }
    return [];
  }, [getHeartland.status, getHeartland.heartland]);

  React.useEffect(() => {
    const newPostfix = () => {
      if (getInventorySettings.inventorySetting) {
        const settings = getInventorySettings.inventorySetting;
        switch (state.newSetType) {
          case 'retired': return settings.retired_set_postfix ? `${settings.retired_set_postfix}` : "";
        }
      }
      return '';
    };
    const usedPostfix = () => {
      if (getInventorySettings.inventorySetting) {
        const settings = getInventorySettings.inventorySetting;
        switch (state.usedSetType) {
          case 'build': return settings.build_only_set_postfix ? `${settings.build_only_set_postfix}` : "";
          case 'certified': return settings.certified_set_postfix ? `${settings.certified_set_postfix}` : "";
          case 'complete': return settings.complete_set_postfix ? `${settings.complete_set_postfix}` : "";
          case 'project': return settings.project_set_postfix ? `${settings.project_set_postfix}` : "";
          case 'open': return settings.open_box_postfix ? `${settings.open_box_postfix}`: "";
        }
      }
      return '';
    };

    let itemNumber = "";
    if (state.itemNumberType === "set_number") {
      if (state.department === "new_sets") {
        itemNumber = props.legoSet.set_number + newPostfix();
      } else if (state.department === "used_sets") {
        itemNumber = props.legoSet.set_number + usedPostfix();
      }
    }
    setState({ ...state, itemNumber: itemNumber });
  }, [state.itemNumberType, state.newSetType, state.usedSetType, state.department, props.legoSet, getInventorySettings.inventorySetting]);

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          {info &&
            <InfoBar status={info.status} message={info.message} onClose={() => setInfo(null)} />
          }
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="department-select-label">Department</InputLabel>
            <Select
              labelId="department-select-label"
              id="Department"
              value={state.department}
              onChange={(e) => update('department', e.target.value)}
              label="Department"
            >
              <MenuItem value="new_sets">New Sets</MenuItem>
              <MenuItem value="used_sets">Used Sets</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          {state.department === 'used_sets' &&
            <FormControl fullWidth>
              <InputLabel id="used-set-type-label">Used Set Type</InputLabel>
              <Select
                labelId="used-set-type-label"
                id="used-set-type"
                value={state.usedSetType}
                onChange={(e) => update('usedSetType', e.target.value)}
                label="Used Set Type"
              >
                <MenuItem value="build">Build Only</MenuItem>
                <MenuItem value="certified">Certified</MenuItem>
                <MenuItem value="complete">Complete</MenuItem>
                <MenuItem value="open">Open Box</MenuItem>
                <MenuItem value="project">Project Bag/Set</MenuItem>
              </Select>
            </FormControl>
          }
          {state.department === 'new_sets' &&
            <FormControl fullWidth>
              <InputLabel id="used-set-type-label">New Set Type</InputLabel>
              <Select
                labelId="new-set-type-label"
                id="new-set-type"
                value={state.newSetType}
                onChange={(e) => update('newSetType', e.target.value)}
                label="New Set Type"
              >
                <MenuItem value="current">Current</MenuItem>
                <MenuItem value="retired">Retired</MenuItem>
              </Select>
            </FormControl>
          }
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="item-number-select-label">Item Number</InputLabel>
            <Select
              labelId="item-number-select-label"
              id="Item Number"
              value={state.itemNumberType}
              onChange={(e) => update('itemNumberType', e.target.value)}
              label="Item Number"
            >
              <MenuItem value="set_number">Use LEGO Set Number</MenuItem>
              <MenuItem value="heartland">Heartland Assigned Number</MenuItem>
              <MenuItem value="custom">Custom Number</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Heartland Item Number"
            fullWidth value={state.itemNumber}
            disabled={state.itemNumberType !== "custom"}
            onChange={(e) => update('itemNunber', e.target.value)}
          />
        </Grid>
        <Box sx={{ width: "100%" }} />
        <Grid item xs={12}>
          <TextField label="Description" fullWidth value={legoSetName} onChange={(e) => { update('description', e.target.value) }} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Long Description" multiline rows={3} fullWidth value={props.legoSet.description} />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput fullWidth label="Defaut Cost" value={0.00} size="medium" onChange={(e) => { update('defaultCost', e.target.value) }} />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput fullWidth label="Current Price" value={0.00} size="medium" onChange={(e) => { update('currentPrice', e.target.value) }} />
        </Grid>
        <Grid item xs={2}>
          <TextField variant="standard" fullWidth label="Default Margin" value={formatPercentage(1 - (state.defaultCost / state.currentPrice))} size="medium" InputProps={{ readOnly: true, }} />
        </Grid>
        {ItemCustomFields?.map((item) => {
          if (item.key === 'bricklink_id') {
            return (
              <Grid item xs={2} key={item.name}>
                <TextField label={item.name} fullWidth value={state.custom.bricklink_id} InputProps={{ readOnly: true, }} />
              </Grid>
            );
          } else if (item.key === 'upc') {
            return (
              <Grid item xs={2} key={item.name}>
                <TextField label={item.name} fullWidth value={props.legoSet.upc} />
              </Grid>
            );
          } else if (item.key === 'tax_category') {
            return (
              <Grid item xs={2} key={item.name}>
                <FormControl fullWidth>
                  <InputLabel id="taxable-select-label">Taxable</InputLabel>
                  <Select
                    labelId="taxable-select-label"
                    fullWidth
                    id="Taxable"
                    value={state.custom.tax_category || "Yes"}
                    onChange={(e) => update('tax_category', e.target.value, true)}
                    label="Taxable"
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            );
          } else if (item.key === 'category') {
            return (
              <Grid item xs={2} key={item.name}>
                <TextField
                  label={item.name}
                  fullWidth
                  value={state.custom.category}
                  onChange={(e) => { update('category', e.target.value, true) }}
                />
              </Grid>
            );
          } else {
            return (
              <Grid item xs={2} key={item.name}>
                <TextField
                  label={item.name}
                  value={state.custom[item.key]}
                  fullWidth
                  onChange={(e) => { update(item.key, e.target.value, true) }}
                />
              </Grid>
            );
          }
        })}
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button startIcon={<AddIcon />} disabled={heartlandImport.status === api.loading} variant="contained" onClick={save}>
                {heartlandImport.status === api.loading ? "Importing" : "Add"}
              </Button>
            </Grid>
            <Grid item>
              <Button startIcon={<CancelIcon />} onClick={() => props.onClose(false)} variant="contained">Cancel</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};