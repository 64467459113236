import * as React from 'react';
import moment from 'moment';
import { Box, Grid } from '@mui/material';

import { BuyModel, NewSetBuyLineModel, UsedSetBuyLineModel } from 'model';
import { formatCurrency } from 'lib';

interface UsedSetTagProps {
  buyId: number;
  updatedAt: string | undefined;
  line: UsedSetBuyLineModel;
  showMinifigs: boolean;
}

const UsedSetTag: React.FC<UsedSetTagProps> = ({ buyId, updatedAt, line, showMinifigs }: UsedSetTagProps) => {

  return (
    <Grid container spacing={1} sx={{ p: 4, my: 2, border: '1px solid #333' }} >
      <Grid item xs={12} sx={{ fontSize: '1.1em', fontWeight: 700 }}>
        {line.lego_set.set_number} {line.lego_set.name}
      </Grid>
      <Grid item xs={2}>
        Cost:
      </Grid>
      <Grid item xs={4}>
        {formatCurrency(line.cost_final, true)}
      </Grid>
      <Grid item xs={2}>
        Condition:
      </Grid>
      <Grid item xs={4}>
        {line.complete}
        {line.certifiable ? " (CERTIFIABLE)" : ""}
      </Grid>
      <Box sx={{ width: "100%" }} />

      <Grid item xs={2}>
        Adj Value:
      </Grid>
      <Grid item xs={4}>
        {formatCurrency(line.value - line.value_discount, true)}
      </Grid>
      <Grid item xs={2}>
        Buy ID:
      </Grid>
      <Grid item xs={4}>
        PATRON-{buyId}
      </Grid>
      <Box sx={{ width: "100%" }} />
      <Grid item xs={2}>
        Orig. Value:
      </Grid>
      <Grid item xs={4}>
        {formatCurrency(line.value, true)}
      </Grid>
      <Grid item xs={2}>
        Date
      </Grid>
      <Grid item xs={4}>
        {moment(updatedAt).format("L")}
      </Grid>
      <Box sx={{ width: "100%" }} />

      <Grid item xs="auto">
        Notes:
      </Grid>
      <Grid item xs={10}>
        {line.notes}
      </Grid>
      {showMinifigs &&
        <>
          {line.lego_set.minifigs.length > 0 &&
            <Grid item xs="auto">
              Minifigs:
            </Grid>
          }
          {line.lego_set.minifigs.map((inventory) => (
            <Grid item xs={12} sx={{ fontSize: '0.8em' }}>
              ___ {inventory.quantity}x {inventory.minifig.name} ({inventory.minifig.bricklink_id})
            </Grid>
          ))}
        </>
      }
    </Grid>
  );
}

interface NewSetTagProps {
  buyId: number;
  updatedAt: string | undefined;
  line: NewSetBuyLineModel;
}
const NewSetTag: React.FC<NewSetTagProps> = ({ buyId, updatedAt, line }: NewSetTagProps) => {
  const condition = React.useMemo(() => {
    const cond: string[] = []
    if (line.opened) {
      cond.push("Open Box")
    }
    if (line.damaged) {
      cond.push("Damaged Box")
    }
    return cond.join(",")
  }, [line]);

  return (
    <Grid container spacing={1} sx={{ p: 4, my: 2, border: '1px solid #333' }} >
      <Grid item xs={12} sx={{ fontSize: '1.1em', fontWeight: 700 }}>
        {line.lego_set.set_number} {line.lego_set.name}
      </Grid>
      <Grid item xs={1.5}>
        Buy ID
      </Grid>
      <Grid item xs={4.5}>
        PATRON-{buyId}
      </Grid>
      <Grid item xs={1}>
        Date
      </Grid>
      <Grid item xs={5}>
        {moment(updatedAt).format("L")}
      </Grid>
      <Grid item xs={1}>
        Cost
      </Grid>
      <Grid item xs={2}>
        {formatCurrency(line.cost_final, true)}
      </Grid>
      <Grid item xs={1}>
        Value:
      </Grid>
      <Grid item xs={2}>
        {formatCurrency(line.value - line.value_discount, true)}
      </Grid>
      <Grid item xs="auto">
        Condition:
      </Grid>
      <Grid item xs={4}>
        {condition}
      </Grid>
      <Grid item xs="auto">
        Notes:
      </Grid>
      <Grid item xs={10}>
        {line.notes}
      </Grid>
    </Grid>
  );
}

interface Props {
  buy: BuyModel;
  includeNewSets: boolean;
  includeUsedSets: boolean;
  includeMinifigInventory: boolean;
}

export const ProcessingTags: React.FC<Props> = ({ buy, includeMinifigInventory, includeNewSets, includeUsedSets }: Props) => {
  let ct = -1;
  return (
    <div style={{ margin: 40 }}>
      {includeUsedSets && buy.used_set_buy_lines.map((line) => {
        ct++
        return (
          <>
            {ct % 4 === 0 && <div key={line.id} style={{ pageBreakBefore: "always", marginTop: 80 }} />}
            <UsedSetTag buyId={buy.id} updatedAt={buy.updated_at} line={line} showMinifigs={includeMinifigInventory} />
          </>
        );
      })}
      {includeNewSets && buy.new_set_buy_lines.map((line) => {
        ct++
        return (
          <>
            {ct % 4 === 0 && <div key={line.id} style={{ pageBreakBefore: "always", marginTop: 80 }} />}
            <NewSetTag buyId={buy.id} updatedAt={buy.updated_at} line={line} />
          </>
        );
      })}
    </div>
  );
};
