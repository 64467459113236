import * as React from 'react';
import { Box, CircularProgress, Grid, Link, Typography } from '@mui/material';
import { AnimalModel } from 'model';
import { useHeartlandItems } from 'api/lego_set/heartland_items';
import { SessionContext, TSessionContext, api, formatCurrency } from 'lib';

interface Props {
  animal: AnimalModel | null;
}

export const AnimalCard: React.FC<Props> = ({ animal }: Props) => {
  const { currentHeartland } = React.useContext(SessionContext) as TSessionContext;
  const { getItem, items, status } = useHeartlandItems();

  React.useEffect(() => {
    if (animal && currentHeartland) {
      getItem(currentHeartland.id, animal);
    }
  }, [animal, currentHeartland, getItem]);

  const maxDim = 300;

  if (!animal) {
    return (<></>);
  }
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6">{animal.name}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Grid container>
            <Grid item xs={12}>
              <Box sx={{ textAlign: 'center', margin: '0 auto 16px', height: maxDim }}>
                {animal.image_url ?
                  <img alt={animal.name} title={animal.name} src={`${animal.image_url}`} style={{ width: 'auto', height: 'auto', maxWidth: maxDim, maxHeight: maxDim }} />
                  : <img src="/unavailable.png" style={{ width: 'auto', height: 'auto', maxWidth: maxDim, maxHeight: maxDim }} />
                }
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={0.25}>
                <Grid item xs={4}>
                  <Typography variant="body2">Bricklink:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">
                    <Link target="_bl" href={`https://www.bricklink.com/v2/catalog/catalogitem.page?P=${animal.bricklink_id}`}>{animal.bricklink_id}</Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              Pricing Coming Soon
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}><Typography variant="body1" sx={{ fontWeight: 'bold' }}>My Store</Typography></Grid>
                {status === api.loading &&
                  <Grid item xs={12}><CircularProgress /> Loading Data</Grid>
                }
                {status === api.success && items.length > 0 &&
                  <>
                    <Grid item xs={2}>Item #</Grid>
                    <Grid item xs={2}>Department</Grid>
                    <Grid item xs={2}>Category</Grid>
                    <Grid item xs={4}>Description</Grid>
                    <Grid item xs={2}>Current</Grid>
                    {items.map((item) => (<>
                      <Grid item xs={2}>{item.public_id}</Grid>
                      <Grid item xs={2}>{item.custom.department}</Grid>
                      <Grid item xs={2}>{item.custom.category}</Grid>
                      <Grid item xs={4}> {item.description}</Grid>
                      <Grid item xs={2}>{formatCurrency(item.price)}</Grid>
                    </>))}
                  </>
                }
                {status === api.success && items.length === 0 &&
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ p: 2 }}>
                      Integrate store pricing by adding bricklink_id if you have this animal
                      itemized in your Heartland Inventory
                    </Typography>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid >
        </Grid>
      </Grid>
    </Box >
  );
};
